<template>
    <div>
        <b-card no-body class="mb-1">
            <b-card-header class="pb-0">
                <strong class="mr-auto">فلترة حسب</strong>
                <b-button
                    type="submit"
                    @click="fetchSubject(filterSubjectDto)"
                    class="mr-1"
                    variant="primary"
                    >فلترة</b-button
                >
                <b-button @click="resetFilterDto()" variant="secondary"
                    >تهيئة</b-button
                >
            </b-card-header>
            <!-- {{ filterSubjectDto }} -->
            <b-card-body class="pb-50">
                <b-row align-v="center">
                    <b-col cols="12" md="4">
                        <EKInputSelect
                            label="الكلية"
                            placeholder="اختر الكلية "
                            :options="[{ id: 0, name: 'الكل' }, ...faculties]"
                            name="select"
                            :clearable="true"
                            v-model="filterSubjectDto.facultyId"
                        />
                    </b-col>
                    <b-col cols="12" md="4">
                        <EKInputSelect
                            label="السنة"
                            placeholder="اختر السنة "
                            :options="[{ id: 0, name: 'الكل' }, ...subjectYear]"
                            name="select"
                            :clearable="true"
                            v-model="filterSubjectDto.year"
                        />
                    </b-col>
                    <b-col cols="12" md="4">
                        <EKInputSelect
                            label="الفصل"
                            placeholder="اختر الفصل "
                            :options="[{ id: 0, name: 'الكل' }, ...semester]"
                            name="select"
                            :clearable="true"
                            v-model="filterSubjectDto.semesterId"
                        />
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <!-- <pre>{{ filterListSubject }}</pre> -->
        <EKTable
            :items="subjectsList"
            :columns="columns"
            selectedLabel="id"
            @details="openSubjectDEtails"
            @delete-selected="subjectList"
        >
        </EKTable>
    </div>
</template>
<script>
import EKTable from "@Ekcore/components/EK-table";
import { mapActions, mapGetters, mapState } from "vuex";
import EKInputSelect from "@Ekcore/components/EK-forms/EK-input-select";

export default {
    components: {
        EKTable,
        EKInputSelect
    },
    data: () => ({
        columns: [
            {
                label: "اسم المادة",
                field: "name"
            },
            {
                label: "الفصل",
                field: "semester"
            },
            {
                label: "اسم الكلية",
                field: "facultyName"
            },
            {
                label: "عدد الدورات",
                field: "examCount",
                type: "number"
            },
            {
                label: "عدد البنوك",
                field: "bankCount",
                type: "number"
            },
            {
                label: "عدد الأسئلة الكتابية",
                field: "interviewCount",
                type: "number"
            },
            {
                label: "عدد المحاضرات الصورية",
                field: "microscopeCount",
                type: "number"
            },
            {
                label: "عدد المحاضرات",
                field: "filesCount"
            },
            {
                label: "تفاصيل",
                field: "details",
                sortable: false
            }
        ],
        filterSubjectDto: {
            semesterId: "",
            year: "",
            facultyId: ""
        }
    }),
    computed: {
        ...mapState({
            faculties: state => state.faculties.faculties,
            subjectYear: state => state.globalStore.subjectYear,
            subjects: state => state.subjects.subjectsList
        }),
        ...mapGetters(["semester", "filterListSubject", "subjectsList"])
    },
    created() {
        this.fetchTotalTag();
        this.getFacultiesDetails();
        this.fetchSubject(this.filterSubjectDto);
    },
    methods: {
        ...mapActions([
            "fetchSubject",
            "fetchTotalTag",
            "getFacultiesDetails",
            "deleteSubjList"
        ]),
        openSubjectDEtails(props) {
            this.$router.push("/subjects/" + props.row.id);
        },
        sendFilter() {
            this.$store.commit("FILTER_SUBJECT", this.filterSubjectDto);
        },
        subjectList(list) {
            this.deleteSubjList(list);
        },
        resetFilterDto() {
            Object.assign(this.filterSubjectDto, {
                semesterId: 0,
                year: 0,
                facultyId: 0
            });
        }
    },

    beforeDestroy() {
        this.$store.commit("Reset_filter_Dto");
        this.$store.commit("Reset_Search_Dto");
    }
};
</script>
